.RainbowLogo {
  animation: RainbowLogo-shadow-change infinite 10s linear;
  pointer-events: none;
}

@keyframes RainbowLogo-shadow-change {
  0% { text-shadow: red 0 .108em 0; }
  20% { text-shadow: yellow 0 .108em 0; }
  40% { text-shadow: green 0 .108em 0; }
  60% { text-shadow: blue 0 .108em 0; }
  80% { text-shadow: purple 0 .108em 0; }
  100% { text-shadow: red 0 .108em 0; }
}
