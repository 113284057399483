.SplashScreen {
  position: relative;

  height: 100vh;
  width: 100vw;

  background: #000000;
  color: #ffffff;
}

.SplashScreen .RainbowLogo {
  position: absolute;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -70%);
}